const manipulators = require('../../helpers/manipulators');

export default () => {
    return {
        subtotal: 0,
        disabled: true,

        init() {
            this.updateTotal();
        },

        updateTotal() {

            const invoices = document.querySelectorAll('[name^="item"][name$="[amount]"]:checked');
            let subtotal = 0;
            
            invoices.forEach(invoice => {
                subtotal += parseFloat(invoice.value);
            });
            
            this.disabled = true;
                console.log('-> subtotal: ', subtotal);
            if (subtotal > 0) {
                this.disabled = false;
            }   
            this.subtotal = manipulators.double(subtotal);
        },

        checkSelection(evt) {
            if (evt.target.getAttribute('x-preclickstatus') == "false") {
                const group = document.querySelectorAll('[x-radiogroup="'+evt.target.getAttribute('x-radiogroup')+'"]');
                group.forEach(radio => {
                    radio.setAttribute('x-preclickstatus', 'false');
                });
                evt.target.setAttribute('x-preclickstatus', 'true');
            } else {
                evt.target.setAttribute('x-preclickstatus', 'false');
                evt.target.checked = false;
            }
            this.updateTotal();
        },
        async triggerAuthorizeNetPopup() {
      
            const token = document.querySelector('input[name="token"]');
            const items = [];
            const invoices = document.querySelectorAll('[name^="item"][name$="[amount]"]:checked');
            let subtotal = 0;

            invoices.forEach(invoice => {

                const invoiceId = invoice.getAttribute('data-invoice-id');
                const invoiceName = invoice.getAttribute('data-invoice-name');
                const lineItemName = `Invoice #${invoiceId}`;
                const lineItemDescription = invoice.getAttribute('data-description');

                subtotal += parseFloat(invoice.value);

                items.push({
                    price: invoice.value, // for authorize.net line item unit price
                    name: lineItemName, // for authorize.net item unit name
                    description: lineItemDescription, // for authorize.net line item description
                })
            });

            console.log("-> Items: ", items);

            const requestBody = {
                price: subtotal,
                firstName: document.querySelector('input[id=firstName]').value,
                lastName: document.querySelector('input[id=lastName]').value,
                addressLine1: document.querySelector('input[id=addressLine1]').value,
                addressLine2: document.querySelector('input[id=addressLine2]').value,
                city: document.querySelector('input[id=city]').value,
                state: document.querySelector('input[id="state"]').value,
                postalCode: document.querySelector('input[id=postalCode]').value,
                country: document.querySelector('input[id="country"]').value,
                items: items
            };

            console.log('-> request body for token: ', requestBody);
    
            document.querySelector('div[id="tokenErrorMsgWrapper"]').style.display = 'none';
    
            const response = await (await fetch('/subscriptions/authorize/token', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })).json();
    
            console.log('-> response: ', response);
    
            if (response.token) {
    
                const inputElement = document.querySelector('input[id=inputtoken]')
                inputElement.value = response.token;
        
                document.querySelector('button[id="btnOpenAuthorizeNetPopup"]').click()
    
            }
            else if (response.errorMsg) {
    
            document.querySelector('span[id="tokenErrorMsg"]').innerHTML = response.errorMsg;
            document.querySelector('div[id="tokenErrorMsgWrapper"]').style.display = 'block';
            
    
            }
      
          },
    }
}