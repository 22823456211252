export default () => {
    return {
        value: void 0,
        active: void 0,
        init({ initialCheckedIndex: e = 0 } = {}) {
            let radios = Array.from(this.$el.querySelectorAll("input:not(.hidden)"));

            this.value = radios[e]?.value;
            radios[e].checked = true;
            this.active = this.value;

            for (let radio of radios) {
                radio.addEventListener("change", () => {
                    this.active = radio.value;
                }),
                radio.addEventListener("focus", () => {
                    this.active = radio.value;
                });
            }

            this.selectPrice();
        },
        selectPrice() {
            let term = this.$el;

            if (this.$el.tagName == 'FIELDSET') {
                term = this.$el.querySelector("input[value='"+this.active+"']");
            }

            let price = term.parentElement.querySelector('input.hidden');

            price.checked = true;
        },
    }
};