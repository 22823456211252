import Navigation from "./components/Navigation";
import Subscriptions from "./components/Subscriptions";
import RadioGroup from "./components/RadioGroup";
import Invoices from "./components/Invoices";

const manifest = {
  Navigation,
  Subscriptions,
  RadioGroup,
  Invoices
};

Object.keys(manifest).forEach(key => {
  window[key] = manifest[key];
});
