import { default as Menu } from './modules/menu.js';
import { default as Login } from './modules/login.js';
// import { default as AcceptJS } from './modules/acceptjs.js';

import "./ComponentManifest";

import Alpine from 'alpinejs'
import mask from '@alpinejs/mask'

window.Alpine = Alpine
Alpine.plugin(mask)
Alpine.start()

// Menu.init();
// Login.init();

// console.log("-> typeof Accept: ", typeof Accept);
// if (typeof Accept != "undefined") {
//     console.log("-> initialize accept js");
	// AcceptJS.init();
// }