export default {

  init() {

    const userMenu = document.getElementById('userMenu');

    document.getElementById('toggleUserMenu').addEventListener('click', function(){
        userMenu.classList.toggle('hidden');
    });

  },
  
};