export default () => {
  return {
    stateInput: false,
    stateSelect: false,
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    countryId: null,
    postalCode: null,
    isValidAddress: false,
    // slideOuterHeight: 0,
    // slideInternalHeight: 0,
    // textColumnHeight: 0,

    init() {

    },
    selectSubscription(rateId, hasPremiums, isDefaultOfferAutoRenewal) {

      console.log('-> is default offer auto renewal? ', isDefaultOfferAutoRenewal);
      console.log('-> has premiums? ', hasPremiums);
      console.log('-> rate ID: ', rateId)

      let firstPremiumsDropdown = 'premiums-holder';
      let secondPremiumsDropdown = 'related-premiums-holder';

      if (isDefaultOfferAutoRenewal) {
        firstPremiumsDropdown = 'related-premiums-holder';
        secondPremiumsDropdown = 'premiums-holder';
      }

      if (hasPremiums) {
        if (rateId == 'BASE') {
          document.querySelector(`div[id="${firstPremiumsDropdown}"]`).style.display = 'block';
          document.querySelector(`div[id="${secondPremiumsDropdown}"]`).style.display = 'none';

        }
        else if (rateId == 'BASEAUTO') {
          document.querySelector(`div[id="${firstPremiumsDropdown}"]`).style.display = 'none';
          document.querySelector(`div[id="${secondPremiumsDropdown}"]`).style.display = 'block';
        }
      }
      else {
        document.querySelector(`div[id="${firstPremiumsDropdown}"]`).style.display = 'none';
        document.querySelector(`div[id="${secondPremiumsDropdown}"]`).style.display = 'none';
      }
    },
    countryInit(countryId) {
      this.countryId = countryId;
      this.countryChanged(this.countryId);
    },
    countryChanged() {
      // Clear inputs...
      this.stateSelect = false;
      this.stateInput = false;

      setTimeout(() => {
        if (this.countryId === 'US' || this.countryId === 'CAN') {
          this.stateSelect = true;
          this.stateInit();
        }
        else {
          this.stateInput = true;
          this.state = document.querySelector('[name="subscriber[state]"]');
        }
      }, 50);
    },
    stateInit() {

      let states = null;
      let placeholder = document.createElement("option");

      placeholder.text = "Select a";
      placeholder.value = "";

      if (this.countryId == "US") {
        this.countryId = "US";
        placeholder.text += " State...";
        states = 'US';
      } else if (this.countryId == 'CAN') {
        placeholder.text += " Province...";
        states = 'CAN';
      }

      console.log('-> country changed!');
      console.log("-> show states for -> ", states);

      setTimeout(function () {

        const stateField = document.querySelector('[name="subscriber[state]"]');
        console.log('-> state field: ', stateField);
        if (stateField) {
          console.log('-> clear unrelated states');
          console.log("-> areas not in " + states + " -> ", stateField.querySelectorAll('option:not([data-country="' + states + '"])'));
          stateField.querySelectorAll('option:not([data-country="' + states + '"])').forEach(e => e.remove());
          stateField.prepend(placeholder);
          if (this.$refs && (this.$refs.state == undefined || this.$refs.state.value == '')) {
            stateField.selectedIndex = 0;
          }
        }

      }, 50);

    },
    async validateAddress(submit) {

      const address = {
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        city: this.city,
        state: this.state,
        countryId: this.countryId,
        postalCode: this.postalCode,
      };

      // Validate via MultiPub
      if (this.countryId == '') {

        const validation = await (await fetch('/account/address/validate', {
          method: 'POST',
          body: JSON.stringify({ address: address }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })).json();

        this.isValidAddress = validation.valid;

      } else {
        // We can't validate addresses other than US address via MultiPub
        this.isValidAddress = true;
      }

      // this.isValidAddress = false;

      // return false;

      if (this.isValidAddress && submit) {
        this.$el.submit(); // submit form if valid address
      } else {
        return this.isValidAddress;
      }
    },
    async validateAddressAndTriggerAuthorizeNetPopup(submit) {

      // const stateField = document.querySelector('[name="subscriber[state]"]');

      const token = document.querySelector('input[name="token"]');

      const address = {
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        city: this.city,
        state: this.state,
        countryId: this.countryId,
        postalCode: this.postalCode,
      };

      // Validate via MultiPub
      if (this.countryId == '') {

        const validation = await (await fetch('/account/address/validate', {
          method: 'POST',
          body: JSON.stringify({ address: address }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })).json();

        this.isValidAddress = validation.valid;

      } else {
        // We can't validate addresses other than US address via MultiPub
        this.isValidAddress = true;
      }

      // this.isValidAddress = false;

      // return false;

      if (this.isValidAddress && submit) {

        const transactionType = document.querySelector("form[id=subscriptionForm]").getAttribute('data-type');

        // console.log('-> transactionType: ', transactionType);

        const productIdForAuthorizeAndMultiPub = document.querySelector("label.bg-red").getAttribute('data-product-id');
        const autoRenewForMultiPub = document.querySelector("label.bg-red").getAttribute('data-auto-renew');
        const amountForAuthorizeNetAndMultiPub = document.querySelector("label.bg-red").getAttribute('data-price');
        const lineItemNameForAuthorizeNet = document.querySelector("label.bg-red").getAttribute('data-rate-id') + ' ' + document.querySelector("label.bg-red").getAttribute('data-product-id')
        const lineItemDescriptionForAuthorizeNet = document.querySelector("label.bg-red").getAttribute('data-description');
        const termForMultiPub = document.querySelector("label.bg-red").getAttribute('data-term');
        const rateIdForMultiPub = document.querySelector("label.bg-red").getAttribute('data-rate-id');
        const isDefaultOfferAutoRenewal = document.querySelector("label.bg-red").getAttribute('data-is-default-offer-auto-renewal');
        let premiumCode = '';

        // console.log('is default offer auto renewal? ' + isDefaultOfferAutoRenewal);

        if (transactionType === 'renew' || transactionType == 'new') {
          if (document.querySelector('select[id="premiums"]') !== null && document.querySelector('div[id="premiums-holder"]').style.display === 'block') {
            premiumCode = document.querySelector('select[id="premiums"]').value;
          }
          else if (document.querySelector('select[id="premiums"]') !== null && document.querySelector('div[id="related-premiums-holder"]').style.display === 'block') {
            premiumCode = document.querySelector('select[id="related-premiums"]').value;
          }
        }

        // console.log('-> premium code: ' + premiumCode);

        const items = [{
          price: amountForAuthorizeNetAndMultiPub, // for authorize.net line item unit price
          term: termForMultiPub, // pass to multi pub items array
          rateId: rateIdForMultiPub, // pass to multi pub items array
          premiumCode: premiumCode, // pass to multi pub items array
          autoRenew: autoRenewForMultiPub, // pass to multi pub items array
          name: lineItemNameForAuthorizeNet, // for authorize.net item unit name
          productId: productIdForAuthorizeAndMultiPub, // for authorize.net order description and multi pub items array
          description: lineItemDescriptionForAuthorizeNet, // for authorize.net line item description
        }];

        const requestBody = {
          price: document.querySelector("label.bg-red").getAttribute('data-price'), // hacky way to get the selected product (aka which radio button is active)
          firstName: document.querySelector('input[id=firstName]').value,
          lastName: document.querySelector('input[id=lastName]').value,
          addressLine1: document.querySelector('input[id=addressLine1]').value,
          addressLine2: document.querySelector('input[id=addressLine2]').value,
          city: document.querySelector('input[id=city]').value,
          state: document.querySelector('[name="subscriber[state]"]').value,
          postalCode: document.querySelector('input[id=postalCode]').value,
          country: document.querySelector('select[id="country"]').value,
          title: document.querySelector('select[id="titleCode"').value,
          // email: document.querySelector('input[id=email]').value, // not needed/allowed for authorize.net
          // phone: document.querySelector('input[id=phone]').value, // not needed/allowed for authorize.net
          items: items
        };

        switch (transactionType) {
          case 'new':
            requestBody.newSubscriber = {
              email: document.querySelector('input[id=email').value,
              phoneNumber: document.querySelector('input[id=phone').value,
            };
            if (document.querySelector('input[id="emailSolicitType"').checked) {
              requestBody.newSubscriber.emailSolicitType = 'YES'
            }
            break;
          case 'renew':
            requestBody.existingSubscriber = {
              email: document.querySelector('input[id=email').value,
              phoneNumber: document.querySelector('input[id=phone').value
            };
            break;
          case 'gift':
            requestBody.newSubscriber = {
              email: document.querySelector('input[id=email').value,
              phoneNumber: document.querySelector('input[id=phone').value
            };
            requestBody.donor = {
              email: document.querySelector('input[id=billto-email]').value
            };
            break;
        }

        // console.log('-> AddressValidation -> request body: ', requestBody);

        document.querySelector('div[id="tokenErrorMsgWrapper"]').style.display = 'none';

        const response = await (await fetch('/subscriptions/authorize/token', {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })).json();

        if (response.token) {

          const inputElement = document.querySelector('input[id=inputtoken]')
          inputElement.value = response.token;

          document.querySelector('button[id="btnOpenAuthorizeNetPopup"]').click()

        }
        else if (response.errorMsg) {

          document.querySelector('span[id="tokenErrorMsg"]').innerHTML = response.errorMsg;
          document.querySelector('div[id="tokenErrorMsgWrapper"]').style.display = 'block';

        }

      } else {
        return this.isValidAddress;
      }
    },
    isUS() {

      if (this.countryId == '' || this.countryId == 'US') {
        return true;
      }

      return false;

    },
    isCANADA() {
      if (this.countryId == 'CAN') {
        return true;
      }
    },
    isWORLD() {

      if (this.countryId !== '' && this.countryId !== 'CAN') {
        return true;
      }
      return false;
    },
  };
};
