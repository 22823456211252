let Manipulators = {
	// Create our number formatter.
	currencyFormat: function() {
		let number = new Intl.NumberFormat('en-US', {
		  style: 'currency',
		  currency: 'USD',

		  // These options are needed to round to whole numbers if that's what you want.
		  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		});
		return number.format;
	},
	format: function(value) {
		let formatCurrency = new Intl.NumberFormat(undefined, {
			style: 'currency',
			currency: 'USD'
		});

		return formatCurrency.format(value);
	},
	double: function(value) {
		let formatCurrency = new Intl.NumberFormat(undefined, {
			style: 'currency',
			currency: 'USD'
		});

		return formatCurrency.format(value).replace('$', '');
	},
}

module.exports = Manipulators;